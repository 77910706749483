import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { commonSetSort, defaultPagination } from 'ducks/common/pagination';
import { SortDirection } from 'ducks/common/types';
import { FilterTypes } from './types';

const defaultFilters = {
    messageCodes: '',
    startTime: '',
    endTime: '',
};

export const initialState = {
    loading: false,
    error: '',
    telemetryEventIds: [],
    telemetryEventsById: {},
    filters: defaultFilters,
    sort: {
        value: 'timestamp',
        direction: SortDirection.Desc,
    },
    pagination: defaultPagination,
};

const vehicleTelemetryEventsSlice = createSlice({
    name: 'vehicleTelemetryEvents',
    initialState,
    reducers: {
        fetchVehicleTelemetryEvents(state) {
            state.loading = true;
            state.error = '';
        },
        fetchVehicleTelemetryEventsSuccess(state, action) {
            state.telemetryEventIds = action.payload.telemetryEventIds;
            state.telemetryEventsById = action.payload.telemetryEventsById;
            state.pagination = action.payload.pagination;
            state.loading = false;
        },
        fetchVehicleTelemetryEventsError(state, action) {
            state.error = action.payload.error;
            state.loading = false;
        },
        setFilter(
            state,
            {
                payload,
            }: PayloadAction<{
                filterType: FilterTypes;
                value: string;
            }>,
        ) {
            state.filters = {
                ...state.filters,
                [payload.filterType]: payload.value,
            };
            state.pagination = defaultPagination;
            state.loading = true;
        },
        setPage(state, { payload }: PayloadAction<{ page: number }>) {
            state.pagination.number = payload.page;
            state.loading = true;
        },
        setSort(
            state,
            {
                payload,
            }: PayloadAction<{
                value: string;
            }>,
        ) {
            commonSetSort(state, payload.value);
            state.loading = true;
        },
        resetFilters(state) {
            state.filters = defaultFilters;
            state.pagination = defaultPagination;
            state.telemetryEventIds = [];
            state.telemetryEventsById = {};
        },
    },
});

export const {
    fetchVehicleTelemetryEvents,
    fetchVehicleTelemetryEventsError,
    fetchVehicleTelemetryEventsSuccess,
    setFilter,
    setSort,
    setPage,
    resetFilters,
} = vehicleTelemetryEventsSlice.actions;

export const vehicleTelemetryEventsReducer =
    vehicleTelemetryEventsSlice.reducer;
