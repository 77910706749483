export enum SortDirection {
    Asc = 'asc',
    Desc = 'desc',
}
export type Sort = {
    value: string;
    direction: SortDirection;
};

export type Pagination = {
    totalPages: number;
    totalElements: number;
    size: number;
    number: number;
};

export type SortStore = {
    sort: Sort;
};

interface ById<T> {
    [id: number]: T;
}

export interface Accumulator<T> {
    byId: ById<T>;
    allIds: number[];
}
