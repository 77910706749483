import { TFunction } from 'i18next';
import React, { Dispatch } from 'react';
import {
    ScooterTelemetryEventsType,
    TelemetryEvent,
} from 'ducks/vehicleTelemetryEvents/types';
import { setSort } from 'ducks/vehicleTelemetryEvents';
import { LinkComponent } from '../../../common/Link';
import { Modals, openModal } from 'ducks/ui';

export const getColumns = (
    t: TFunction,
    dispatch: Dispatch<any>,
    type: ScooterTelemetryEventsType,
) => {
    const baseColumns = [
        {
            title: t('scooterTelemetryEvents.timeStamp'),
            field: 'timestamp',
            render: (rowProps: TelemetryEvent<typeof type>) =>
                rowProps.timestamp
                    ? new Date(rowProps.timestamp).toLocaleString()
                    : '-',
            onClick: () =>
                dispatch(
                    setSort({
                        value: 'timestamp',
                    }),
                ),
        },
    ];

    if (type === ScooterTelemetryEventsType.location) {
        return [
            ...baseColumns,
            {
                title: t('scooterTelemetryEvents.gps'),
                field: 'location',
                sorting: false,
                // eslint-disable-next-line react/display-name
                render: (
                    rowProps: TelemetryEvent<ScooterTelemetryEventsType.location>,
                ) => (
                    <LinkComponent
                        text={`${
                            rowProps.fields.location
                                ? rowProps.fields.location[1]
                                : '-'
                        }, ${
                            rowProps.fields.location
                                ? rowProps.fields.location[0]
                                : '-'
                        }`}
                        handleClick={() =>
                            dispatch(
                                openModal({
                                    modalType: Modals.MAP_WITH_POINT,
                                    modalProps: {
                                        position: {
                                            lat: rowProps.fields.location[1],
                                            lng: rowProps.fields.location[0],
                                        },
                                        title: t(
                                            'scooterTelemetryEvents.scooterPosition',
                                        ),
                                    },
                                }),
                            )
                        }
                    />
                ),
            },
        ];
    }

    if (type === ScooterTelemetryEventsType.mileage) {
        return [
            ...baseColumns,
            {
                title: t('scooterTelemetryEvents.mileage'),
                field: 'mileage',
                sorting: false,
                render: (
                    rowProps: TelemetryEvent<ScooterTelemetryEventsType.mileage>,
                ) => rowProps.fields.mileage,
            },
        ];
    }

    if (type === ScooterTelemetryEventsType.batteryLevel) {
        return [
            ...baseColumns,
            {
                title: t('scooterTelemetryEvents.battery1Level'),
                field: 'battery1Level',
                sorting: false,
                render: (
                    rowProps: TelemetryEvent<ScooterTelemetryEventsType.batteryLevel>,
                ) => rowProps.fields.battery1Level,
            },
            {
                title: t('scooterTelemetryEvents.battery2Level'),
                field: 'battery2Level',
                sorting: false,
                render: (
                    rowProps: TelemetryEvent<ScooterTelemetryEventsType.batteryLevel>,
                ) => rowProps.fields.battery2Level,
            },
            {
                title: t('scooterTelemetryEvents.batteryLevel'),
                field: 'batteryLevel',
                sorting: false,
                render: (
                    rowProps: TelemetryEvent<ScooterTelemetryEventsType.batteryLevel>,
                ) => rowProps.fields.batteryLevel,
            },
            {
                title: t('scooterTelemetryEvents.dashboardBatteryLevel'),
                field: 'dashboardBatteryLevel',
                sorting: false,
                render: (
                    rowProps: TelemetryEvent<ScooterTelemetryEventsType.batteryLevel>,
                ) => rowProps.fields.dashboardBatteryLevel,
            },
        ];
    }

    if (type === ScooterTelemetryEventsType.speed) {
        return [
            ...baseColumns,
            {
                title: t('scooterTelemetryEvents.speed'),
                field: 'speed',
                sorting: false,
                render: (
                    rowProps: TelemetryEvent<ScooterTelemetryEventsType.speed>,
                ) => rowProps.fields.speed,
            },
        ];
    }

    return [];
};
