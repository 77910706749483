import { TFunction } from 'i18next';
import { Dispatch } from 'react';
import { FetchedTelemetryEvent } from 'ducks/vehicleTelemetryEvents/types';
import { setSort } from 'ducks/vehicleTelemetryEvents';

export const getColumns = (t: TFunction, dispatch: Dispatch<any>) => {
    return [
        {
            title: t('bikeAndVaeTelemetryEvents.timeStamp'),
            field: 'timestamp',
            render: (rowProps: FetchedTelemetryEvent) =>
                rowProps.timestamp
                    ? new Date(rowProps.timestamp).toLocaleString()
                    : '-',
            onClick: () =>
                dispatch(
                    setSort({
                        value: 'timestamp',
                    }),
                ),
        },
        {
            title: t('bikeAndVaeTelemetryEvents.type'),
            field: 'type',
            render: (rowProps: FetchedTelemetryEvent) =>
                t(`bikeAndVaeTelemetryEvents.types.${rowProps.msgCode}`),
            sorting: false,
        },
    ];
};
